// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$family-sans-serif: 'Fira Sans', 'Segoe UI', 'Source Sans Pro', Calibri, Candara, Arial, sans-serif;

// Brand colors
$blue: #007ac3;
$light-green: #85bc20;
$dark-green: #009881;
$orange: #ea8f00;

$green75: #a4cd58;
$green50: #a4cd58;
$green35: #d5e881;

$grey10: #ececec;

// Set vars
$radius: 0;
$radius-small: 0;
$radius-large: 0;

$primary: $blue;
$success: $green75;
