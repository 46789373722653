@charset "utf-8";

// Icons
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

// Bulma steps
@import './_variables.scss';
@import '../../node_modules/bulma-o-steps/bulma-steps.sass';

// Bulma
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/components/_all.sass';
@import '../../node_modules/bulma/sass/elements/_all.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma/sass/grid/_all.sass';
@import '../../node_modules/bulma/sass/layout/_all.sass';
@import './overrides';

// Tooltip
@import '../../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css'