body {
  min-height: 100vh;
  background-color: #dadada;
}

.button {
  border-radius: $radius;
}

.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    &:after {
      @include loader;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 2em;
      height: 2em;
      border-width: 0.25em;
    }
  }
}

.box-shadow {
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.box-center-items {
  align-items: center;
  justify-content: center;
}

.button-select {
  border-style: solid;
  border-color: $black;
  background-color: $white;
  border-width: 1px;
  border-radius: $radius;
  padding: 1.5rem;
  cursor: pointer;

  &.is-active {
    border-color: $success;
    background-color: #f3ffe7;
    border-width: 2px;
  }

  & .text-container {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;

    & .text-subtitle {
      font-size: 0.65rem;
    }
  }
}

.summary-box {
  padding: 8px;
  background-color: $grey10;
  border-radius: $radius;
}

.language-img {
  cursor: pointer;
  width: 1.5rem;

  &.language-selected {
    width: 2.5rem;
  }
}
